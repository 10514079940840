'use client';

import { useEffect } from 'react';
import {
    usePathname,
    useSearchParams,
} from 'next/navigation';
import { safe } from '@frontend/jetlend-core/src/utils/globalUtils';
import { parseCookie } from 'next/dist/compiled/@edge-runtime/cookies';
import { IProps } from './YandexMetrika';
import { PAGE_FEATURES_COOKIE_NAME } from '@app/utils/constants';

/**
 * Компонент для отслеживания переходов между страницами.
 */
export default function YandexMetrikaClient({ id }: IProps) {
    const pathName = usePathname();
    const searchParams = useSearchParams();

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        // Обновляем пользовательские фичи из куки
        // При переходе между страницами могут выдаться новые фичи и нам нужно их обновить на Frontend и аналитике
        (async () => {
            try {
                // Читаем и парсим куки в объект
                const featuresInCookie = await (window as any).cookieStore.get(PAGE_FEATURES_COOKIE_NAME);
                const features = featuresInCookie
                    ? Object.fromEntries(parseCookie(decodeURIComponent(featuresInCookie.value)))
                    : {};

                // Сохраняем в глобальный контекст + отправляем в аналитику обновленный список
                (window as any).USER_FEATURES = features;
                (window as any).ym(id, 'params', {
                    features,
                });
            } catch (e) {
                console.error(e);
            }
        })();

        // Обновляем информацию по переходу
        safe(() => {
            (window as any).ym(id, 'hit', window.location.href);
        });
    }, [ id, pathName, searchParams ]);

    return null;
}
import { numWordForm } from '@frontend/jetlend-core/src/utils/langUtils';
import {
    IAchievementItemApiModel,
    IAchievementsMapResult,
    IUserCoursesItemApiModel,
} from '@app/models/features/course';

/**
 * Функция для получения текста с информацией о прогрессе курса в часах
 * @param time - прогресс прохождения курса в минутах
 */
export function getEstimatedMinutes(time: number) {
    return numWordForm(isNaN(time) ? 0 : time, 'minutes');
}

/**
 * Mаппинг ачивок по идентификатору
 * @param achievements список ачивок
 */
export function getAchievementsById(achievements: IAchievementItemApiModel[] | undefined): IAchievementsMapResult {
    return achievements?.reduce((map, achievement) => {
        map[achievement?.id] = achievement;
        return map;
    }, {});
}

/**
 * Mаппинг курсов по идентификатору
 * @param courses список курсов
 */
export function getCoursesById(courses: IUserCoursesItemApiModel[] | undefined) {
    return courses?.reduce((map, course) => {
        map[course?.id] = course;
        return map;
    }, {});
}
import React from 'react';
import AppBadgeButton from '@app/ui/AppBadgeButton/AppBadgeButton';

export interface IProps {
    /**
     * Включить темный вариант иконок
     */
    dark?: boolean;
}

export default function InvestorAppBadges({ dark }: IProps) {
    const darkIconPrefix = dark ? '-dark' : '';

    return (
        <>
            <AppBadgeButton
                src={`/next/app-badges/app-store--ru${darkIconPrefix}.svg`}
                title="Скачать приложение из App Store"
                href="https://apps.apple.com/ru/app/jetlend/id1556130355"
            />
            <AppBadgeButton
                src={`/next/app-badges/google-play--ru${darkIconPrefix}.svg`}
                title="Скачать приложение из Google Play"
                href="https://play.google.com/store/apps/details?id=com.jetlend.client"
            />
            <AppBadgeButton
                src={`/next/app-badges/gallery--ru${darkIconPrefix}.svg`}
                title="Открыть в AppGallery"
                href="https://appgallery.huawei.com/app/C105194273"
            />
            <AppBadgeButton
                src={`/next/app-badges/ru-store-badge-ru${darkIconPrefix}.svg`}
                title="Открыть в RuStore"
                href="https://www.rustore.ru/catalog/app/com.jetlend.client"
            />
        </>
    );
}

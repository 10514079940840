import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Tooltip from '@frontend/jetlend-web-ui/src/ui/TooltipIcon/Tooltip';
import styles from './SocialButton.module.scss';

export interface IProps {
    title: string;
    href: string;
    icon: string;
}

const SocialButton = (props: IProps) => {
    const {
        href,
        icon,
        title,
    } = props;

    return (
        <Link className={styles.container} href={href} rel="nofollow noreferrer noopener" target="_blank">
            <Tooltip title={title}>
                <div className={styles.icon}>
                    <Image src={icon} fill alt={title} />
                </div>
            </Tooltip>
        </Link>
    );
};

export default SocialButton;
import Button from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { isEmptyValue } from '@frontend/jetlend-core/src/validators';
import { FormAlert } from '@frontend/jetlend-web-ui/src/ui/forms/FormAlert/FormAlert';
import { MaskInputField } from '@frontend/jetlend-web-ui/src/ui/inputs/MaskInput/MaskInput';
import connectToForm from '@frontend/jetlend-core/src/ducks/connectToForm';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { useActions } from '@frontend/jetlend-core/src/hooks/useActions';
import {
    IPhoneRegistrationFormValues,
    IRegistrationState,
} from '@app/models/common/registration';
import { commonPhoneRegistrationFormHandler } from '@app/ducks/common/registration';
import { CLIENT_TYPE_LOGIN_URLS } from '@app/models/common/common';
import { getCorrectFirstPhoneNumberFigure } from '@app/utils/getCorrectFirstPhoneNumberFigure';
import ExternalAuth from '@app/features/authentication/ExternalAuth/ExternalAuth';

import styles from './stepPhoneForm.module.scss';
import { sendEvent } from '@app/ducks/common/analytics';

export interface IProps {
    state: IRegistrationState;
}

export default connectToForm<IProps, IPhoneRegistrationFormValues>(function StepPhoneForm(props) {
    const clientType = props.state?.clientType;
    const existsUserPhone = props.state?.userExists;
    const errorMessage = props.state?.errorMessage;
    const currentPhone = useSelector(commonPhoneRegistrationFormHandler.formFieldValue('phone'));
    const isChangePhonePressed = props.state?.isChangePhonePressed;

    const isPhoneChangedFromExists = isEmptyValue(existsUserPhone) ||
            (existsUserPhone !== currentPhone);

    const showAlert = useMemo(() => {
        if (isChangePhonePressed) {
            return false;
        }
        return (existsUserPhone && !isPhoneChangedFromExists) || errorMessage;
    }, [isChangePhonePressed, existsUserPhone, isPhoneChangedFromExists, errorMessage]);

    const isButtonDisabled = useMemo(() => {
        if (isChangePhonePressed) {
            return props.invalid || !props.dirty;
        }

        return props.invalid || !props.dirty || !isPhoneChangedFromExists;
    }, [isChangePhonePressed, props.invalid, props.dirty, isPhoneChangedFromExists]);

    const sendEventAction = useActions(sendEvent);
    const didChangeToLoginClicked = useCallback(() => {
        sendEventAction(`${clientType}--registration--to-login`);
    }, [ sendEventAction, clientType ]);

    return (
        <>
            <div className="h-ta-center h-mb-30" data-testid="step-title">
                Введите свой номер телефона
            </div>
            <div className={styles['container']}>
                <div className={styles['input']}>
                    <MaskInputField
                        name="phone"
                        label="Номер телефона"
                        mask="+7 000 000-00-00"
                        prepareChar={getCorrectFirstPhoneNumberFigure}
                        inputType="tel"
                        autoComplete="tel"
                        autoFocus
                    />
                </div>

                <Button
                    className={styles['start-button']}
                    textClassName={styles['start-button__text']}
                    buttonType="submit"
                    text="Начать"
                    noSpacing
                    width={170}
                    disabled={isButtonDisabled}
                    onClick={props.handleSubmit}
                />
            </div>
            <ExternalAuth
                clientType={clientType}
                title="Или зарегистрируйте физическое лицо через:"
                type="register"
                className="h-ta-center h-mt-30"
            />
            <div className="h-ta-center h-mt-30">
                <Link
                    className="link--underline"
                    href={CLIENT_TYPE_LOGIN_URLS[clientType]}
                    onClick={didChangeToLoginClicked}
                >
                    Или авторизируйтесь на платформе
                </Link>
            </div>
            {(showAlert || (props.error && !existsUserPhone)) && (
                <div className={styles['footer']}>
                    {showAlert && (
                        <div className="d-flex--1">
                            <FormAlert
                                alert={errorMessage ?? 'Вы уже зарегистрированы на платформе'}
                            />
                        </div>
                    )}
                    {props.error && !existsUserPhone && (
                        <div className="d-flex--1">
                            <FormAlert alert={props.error} />
                        </div>
                    )}
                </div>
            )}
        </>
    );
},
commonPhoneRegistrationFormHandler,
{
    // Помечаем форму как partial, чтобы значения не сбрасывались при переходе между шагами
    partial: true,
}
);

import { simpleFormHandler } from '@frontend/jetlend-core/src/ducks/form';
import { call } from '@redux-saga/core/effects';
import { apiPostTestSign } from '@app/services/client/testsService';
import { ITestMetadata } from '@app/models/common/tests';

export const VERSION = 2;
export const PREFIX = 'tests';

export const testSignMetadata = simpleFormHandler<ITestMetadata>(PREFIX, 'signed-test-url', undefined, {
    *apiMethod (values: {id: string}) {
        return yield call(apiPostTestSign, values.id);
    },
}, {}
);

'use client';

import React from 'react';
import moment from 'moment';
import {
    buildClassNames,
    mergeClassNames,
} from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import Link from 'next/link';
import { useActions } from '@frontend/jetlend-core/src/hooks/useActions';
import SocialButton from '@app/ui/SocialButton/SocialButton';
import styles from './layoutFooter.module.scss';
import telegramIcon from '@app/icons/telegram-icon.svg';
import dzenIcon from '@app/icons/dzen-icon.svg';
import tpulseIcon from '@app/icons/tpulse-icon.svg';
import smartLabIcon from '@app/icons/smart-lab-icon.svg';
import bksIcon from '@app/icons/bks-icon.svg';
import youtubeIcon from '@app/icons/youtube-icon.svg';
import { IPagesMenuApiModel } from '@app/models/common/pages';
import { LayoutFooterPortalRenderer } from './LayoutFooterSectionPortal';
import { sendEvent } from '@app/ducks/common/analytics';

export interface IProps {
    /**
     * Список ссылок навигации для отображения в футере.
     */
    navigation: IPagesMenuApiModel[];
}

const CompanyLegalAddressElement = (
    <>
        141981, Московская&nbsp;область,{' '}
        г.о.&nbsp;Дубна, г.&nbsp;Дубна,{' '}
        ул.&nbsp;Большеволжская, д.&nbsp;21, офис&nbsp;307
    </>
);

/**
 * Компонент отображения нижнего футера сайта.
 */
export default function LayoutFooter({ navigation }: IProps) {
    const sendEventAction = useActions(sendEvent);
    // Здесь нет смысла использовать useCallback, т.к. используется внутри цикла
    const didEventClicked = (item: IPagesMenuApiModel) => {
        sendEventAction(`site-footer--${item.url}`);
    };

    return (
        <footer className={styles['container']}>
            <section className={buildClassNames(styles, ['section', 'navigation'])} style={{ gridTemplateRows: `repeat(${Math.ceil((navigation?.length ?? 0) / 2)}, 1fr)` }}>
                {navigation && navigation.map(item => (
                    <Link
                        key={item.url}
                        href={item.url}
                        rel="nofollow"
                        onClick={() => didEventClicked(item)}
                    >
                        {item.title}
                    </Link>
                ))}
            </section>
            <section className={buildClassNames(styles, ['section', 'info'])}>
                <div className={styles['address']}>
                    <div className={styles['address__highlight']}>
                        Направить обращение возможно следующими способами:
                    </div>
                    <div className={styles['address__text']}>
                        По юридическому адресу
                        <br />
                        ООО&nbsp;“Джетленд” -{' '}
                        {CompanyLegalAddressElement}
                    </div>
                    <div className={styles['address__text']}>
                        По адресу электронной почты
                        <br />
                        <Link
                            href="mailto:official_request@jetlend.ru"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            official_request@jetlend.ru
                        </Link>
                    </div>
                </div>
                <div className={styles['support']}>
                    <Link href="mailto:support@jetlend.ru" target="_blank" rel="noreferrer noopener">support@jetlend.ru</Link>
                    <br /><br />
                    <Link href="tel:88002229332" target="_blank" rel="noreferrer noopener">8 800 222 9332</Link>
                </div>
            </section>
            <LayoutFooterPortalRenderer sectionType="apps" className={styles['apps']} allowEmpty />
            <section className={buildClassNames(styles, ['section', 'socials'])}>
                <div className={styles['socials__label']}>Наши соц.сети</div>
                <SocialButton
                    title="Telegram"
                    href="https://t.me/jetlend"
                    icon={telegramIcon}
                />
                <SocialButton
                    title="Дзен"
                    href="https://dzen.ru/jetlend"
                    icon={dzenIcon}
                />
                <SocialButton
                    title="Youtube"
                    href="https://www.youtube.com/channel/UCpKCSiRwjqtWgld9tjT0xeQ"
                    icon={youtubeIcon}
                />
                <SocialButton
                    title="Т-Пульс"
                    href="https://www.tbank.ru/invest/social/profile/JetLend/"
                    icon={tpulseIcon}
                />
                <SocialButton
                    title="БКС"
                    href="https://bcs-express.ru/profit/profile/aceb8cfe-28c8-4569-a780-dbb3d5ede872"
                    icon={bksIcon}
                />
                <SocialButton
                    title="Смартлаб"
                    href="https://smart-lab.ru/mobile/users/JetLend/blog/"
                    icon={smartLabIcon}
                />
            </section>
            <section className={buildClassNames(styles, ['section', 'rights'])}>
                <div className={styles['copyright']}>
                    <div className={styles['copyright__text']}>
                        ООО “ДжетЛенд“,{' '}
                        <span className="h-ws-nowrap">ОГРН 1187746779868</span>,{' '}
                        <span className="h-ws-nowrap">ИНН 7724451748</span>,{' '}
                        {CompanyLegalAddressElement}
                    </div>
                    <div className={styles['copyright__text']}>
                        &copy; 2018 – {moment().year()} Все права защищены. «ДжетЛенд», официальный сайт.
                    </div>
                </div>
                <div className={styles['license']}>
                    <div className={styles['copyright__links']}>
                        <Link href="https://www.cbr.ru/finorg/foinfo/?ogrn=1187746779868" rel="nofollow noreferrer noopener" target="_blank">
                            Лицензия оператора инвестиционной платформы №18
                        </Link>
                        <Link href="https://jetlend.ru/doc-view/ustav-kompanii" rel="nofollow noreferrer noopener" target="_blank">
                            Устав компании
                        </Link>
                        <Link href="https://www.e-disclosure.ru/portal/company.aspx?id=38988" rel="nofollow noreferrer noopener" target="_blank">
                            Раскрытие информации по ООО ДжетЛенд
                        </Link>
                        <Link href="https://www.e-disclosure.ru/portal/company.aspx?id=39262" rel="nofollow noreferrer noopener" target="_blank">
                            Раскрытие информации по ПАО ДжетЛенд Холдинг
                        </Link>
                        <Link href="https://jetlend.ru/doc-view/company_insider_info_list" rel="nofollow noreferrer noopener" target="_blank">
                            Перечень инсайдерской информации
                        </Link>
                        <Link href="https://jetlend.ru/privacy-policy-text/" rel="nofollow noreferrer noopener" target="_blank">
                            Политика конфиденциальности
                        </Link>
                    </div>
                </div>
            </section>
            <LayoutFooterPortalRenderer sectionType="notes" className={mergeClassNames(['r-mobile', styles['notes']])} />
        </footer>
    );
}